export const AppRoutes = {
    INDEX: '/',
    // REGISTRATION: '/register',
    // REGISTRATION_MDN: '/register/:mdn',
    // REGISTRATION_OTP: '/register/otp',
    // VERIFY_MOBILE: '/register/verifymobile',
    // VERIFY_MOBILE_OTP: '/register/verifymobile/otp',
    // SIMPLE_REGISTRATION: '/register/simple',
    LANDING: '/landing',
    LOGIN: '/login',
    LOGIN_REDIRECT: '/login/:redirectTo',
    OTP: '/otp',
    DASHBOARD: '/dashboard',
    DASHBOARD_COMPONENT: '/dashboard/:component',
    BREACH: '/breach',
    BREACH_DETAILS: '/breach/:id/:asset',
    BREACH_VERIFY_EMAIL: '/breach/verifyemail/:email',
    ASSESSMENT: '/assessment',
    ASSESSMENT_RESULT: '/assessment/result',
    ASSESSMENT_GUIDE: '/assessment/guide',
    ASSESSMENT_RETAKE: '/assessment/:retake',
    DISCOVER: '/discover-security',
    DISCOVER_ARTICLE: '/discover-security/:slug',
    PROFILE: '/profile',
};

export const PublicRoutes = [
    AppRoutes.INDEX,
    AppRoutes.LOGIN,
    AppRoutes.LOGIN_REDIRECT,
    AppRoutes.OTP,
    // AppRoutes.REGISTRATION,
    // AppRoutes.REGISTRATION_MDN,
    // AppRoutes.SIMPLE_REGISTRATION,
    // AppRoutes.REGISTRATION_OTP,
    // AppRoutes.VERIFY_MOBILE,
    // AppRoutes.VERIFY_MOBILE_OTP,
];

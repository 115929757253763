import { NotAllowedIcon } from '@chakra-ui/icons';
import { Center, Link, Stack, Text } from '@chakra-ui/react';
import Header from '../components/header/Header';
import FullWidth from '../components/layouts/FullWidth';

export const NotRegistered = () => {
    return (
        <FullWidth id={'login-page'} height={'100vh'}>
            <Header authorized={false}></Header>
            <Center
                marginTop={{ base: '84px', md: '112px' }}
                height={{ base: 'calc(100% - 84px)', md: 'calc(100% - 112px)' }}
            >
                <Stack alignItems={'center'}>
                    <NotAllowedIcon boxSize={14} color={'red'} />
                    <Text>
                        Please log in via <Link href="#">MyAsurion</Link>
                    </Text>
                </Stack>
            </Center>
        </FullWidth>
    );
};

export default NotRegistered;

import * as React from 'react';
import { IconProps, Icon } from '@chakra-ui/react';

const HeaderMobileLogoIcon: React.FC<IconProps> = props => (
    <Icon
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.9917 7.52826C10.8583 6.95662 9.95583 3.23926 9.17113 3.47658C8.20281 3.76759 7.36788 7.86084 7.36788 7.86084C7.03183 7.88683 6.52255 7.96305 5.92667 8.0791L6.66633 17.66C7.78535 16.5687 8.82122 15.4826 9.55395 14.6252C9.55395 14.6252 9.89693 15.1795 10.4062 15.9642C11.6846 17.9303 14.0214 21.3358 14.664 21.0327C15.8021 20.4957 12.4381 11.2664 12.4381 11.2664C12.4381 11.2664 17.7023 9.44237 17.2745 8.16918C16.9142 7.09866 11.0073 7.60274 11.0073 7.60274L10.9917 7.52999V7.52826ZM14.011 6.9982C14.7991 7.00166 15.5994 7.04323 16.2282 7.15063C16.5487 7.20606 16.8553 7.28228 17.1047 7.39661C17.3386 7.50227 17.6279 7.68762 17.7387 8.01848C17.9622 8.68538 17.4131 9.24489 17.0077 9.57402C16.5296 9.9603 15.8731 10.3258 15.2322 10.6376C14.4094 11.0395 13.5641 11.3738 13.0617 11.5626C13.3423 12.356 13.933 14.0674 14.4475 15.8152C14.8026 17.0226 15.1213 18.249 15.279 19.2052C15.3586 19.6885 15.4019 20.1337 15.3829 20.4905C15.3673 20.7763 15.2997 21.2769 14.8736 21.4779C14.6623 21.5783 14.451 21.5454 14.3072 21.4969C14.1599 21.4484 14.0231 21.3636 13.9036 21.2769C13.6611 21.1003 13.396 20.8387 13.1275 20.5407C12.5871 19.9379 11.9635 19.1065 11.378 18.2733C11.0177 17.7623 10.6678 17.2443 10.3542 16.7732L10.0286 21.4796L7.16001 24L6.67153 17.667C3.85493 20.4039 0.508264 23.191 0.0596169 22.5189C-0.70776 21.3722 6.1865 11.4847 6.1865 11.4847C5.62526 11.547 0.267484 11.4639 0.345434 10.253C0.397401 9.45449 3.73713 8.5035 5.9284 8.07737L5.3048 0L11.4248 1.26279L11.0194 7.11425C11.9063 7.05882 13.1986 6.99127 14.0092 6.99473L14.011 6.9982Z"
            fill="white"
        />
    </Icon>
);

export default HeaderMobileLogoIcon;

import React, { useEffect } from 'react';
import FullWidth from '../components/layouts/FullWidth';
import { useMutation } from '@tanstack/react-query';
import { fetchAccountDetails, loginOrRegister } from '../services/apiService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../config/config';
import { account, authToken, loginEmail } from '../entities/session';
import Header from '../components/header/Header';
import { Flex, Spinner } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import useErrorToast from '../hooks/useErrorToast';
import { AppConfig } from '../config/config';

const LoginPage: React.FC = ({ ...props }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = searchParams.get('code');
    const redirectPath = searchParams.get('redirect');

    // const [authorized, setAuthorized] = useState<NullableVar<boolean>>(null);

    const errToast = useErrorToast();

    const { mutate: fetchDetails } = useMutation({
        mutationFn: fetchAccountDetails,
        onSuccess: accountData => {
            account.set(accountData.user);
            loginEmail.set(null);

            if (accountData?.user?.primaryEmail) {
                if (redirectPath) {
                    navigate(redirectPath);
                } else {
                    navigate(AppRoutes.DASHBOARD);
                }
            }
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
            navigate(AppRoutes.INDEX);
        },
    });

    const { mutate: doLoginOrRegister } = useMutation({
        mutationFn: loginOrRegister,
        onSuccess: data => {
            if (data?.token) {
                authToken.set(data.token);
                fetchDetails({ email: data.email });
            }
            window.dataLayer.push({
                event: 'SecurityAdvisor_DL_Event Login',
                System: 'Security Advisor',
                Partner: AppConfig.carrier,
                Program: AppConfig.program,
                ID: '',
                // Timestamp: now(),
                Module: 'Login',
                MainSubscriberEmail: loginEmail,
            });
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
            navigate(AppRoutes.INDEX);
        },
    });

    useEffect(() => {
        if (code) {
            doLoginOrRegister(code);
        } else {
            navigate(AppRoutes.INDEX);
        }
    }, [code, doLoginOrRegister, navigate]);

    return (
        <FullWidth id={'login-page'}>
            <Header authorized={false}></Header>

            <Flex marginTop={{ base: '84px', md: '112px' }} justifyContent={'center'}>
                <Spinner variant={'pageLoader'} />
            </Flex>
        </FullWidth>
    );
};

export default LoginPage;
